define('web/adapters/landmark', ['exports', 'web/adapters/application', 'ember-cli-form-data/mixins/form-data-adapter'], function (exports, _webAdaptersApplication, _emberCliFormDataMixinsFormDataAdapter) {
    exports['default'] = _webAdaptersApplication['default'].extend({
        attrs: {
            externalValidations: {
                deserialize: 'records',
                serialize: false
            }
        },

        updateRecord: function updateRecord(store, type, snapshot) {

            var serializer = store.serializerFor(type.modelName);

            var data = serializer.serialize(snapshot);

            var id = snapshot.id;
            var url = this.buildURL(type.modelName, id, snapshot, 'updateRecord');

            delete data.externalValidations;

            return this.ajax(url, "PUT", {
                data: { landmark: data },
                crossDomain: true,
                xhrFields: {
                    withCredentials: true
                }
            });
        },

        createRecord: function createRecord(store, type, snapshot) {
            var serializer = store.serializerFor(type.modelName);

            var data = serializer.serialize(snapshot);

            var url = this.buildURL(type.modelName, null, snapshot, 'createRecord');

            delete data.externalValidations;

            return this.ajax(url, "POST", {
                data: { landmark: data },
                crossDomain: true,
                xhrFields: {
                    withCredentials: true
                }
            });
        }
    });
});