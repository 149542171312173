define('web/mixins/record-editor', ['exports', 'ember', 'web/mixins/error-message'], function (exports, _ember, _webMixinsErrorMessage) {
  var isPresent = _ember['default'].isPresent;
  var get = _ember['default'].get;
  var run = _ember['default'].run;
  exports['default'] = _ember['default'].Mixin.create(_webMixinsErrorMessage['default'], {
    recordIsUpdating: false,
    recordIsUpdated: false,
    recordConfirmDelete: false,
    recordIsDeleted: false,
    deleteError: false,
    errorMessages: null,
    confirmTransition: false,

    supportedFileTypes: ['*.png', '*.jpeg'],

    actions: {
      deleteRecord: function deleteRecord() {
        this.set('recordConfirmDelete', true);
      },

      clearErrors: function clearErrors() {
        this.set('deleteError', false);
        this.set('errorMessages', null);
        this.set('recordConfirmDelete', false);
      },

      clearErrorMessage: function clearErrorMessage() {
        this.set('errorMessages', null);
        if (this.get('recordIsUpdating') === true) {
          this.set('recordIsUpdating', false);
        }
      },

      cancelDelete: function cancelDelete() {
        this.set('recordConfirmDelete', false);
      },

      scrollToBottom: function scrollToBottom() {
        _ember['default'].$('html, body').animate({
          scrollTop: document.body.scrollHeight
        }, 600);
      }
    }
  });
  var RouteRecordEditor = _ember['default'].Mixin.create({
    resetRoute: (function () {
      var routeName = this.routeName.split('.')[0];

      if (routeName === 'home-content') {
        routeName = 'promotion';

        if (get(this.currentModel, 'hasDirtyAttributes')) {
          this.currentModel.rollbackAttributes();
        }
      } else {
        if (get(this.currentModel, 'hasDirtyAttributes')) {
          this.currentModel.rollbackAttributes();
        }

        if (this.modelFor(routeName).get('hasDirtyAttributes')) {
          this.modelFor(routeName).rollbackAttributes();
        }

        if (!this.currentModel.isDestroying) {
          if (this.modelFor(routeName).isDeepDirty()) {
            this.modelFor(routeName).rollback();
          }
        }
      }

      this.controller.setProperties({
        recordConfirmDelete: false,
        deleteError: false,
        errorMessages: null
      });
    }).on('deactivate'),

    actions: {
      willTransition: function willTransition(transition) {
        var routeName = this.routeName.split('.')[0];

        if (!this.modelFor(routeName).get('isDeleted')) {
          var hasDirtyAttributes = this.modelFor(routeName).get('hasDirtyAttributes');
          var hasDirtyRelationships = this.modelFor(routeName).isDeepDirty();
          var firstTry = !this.controller.get('confirmTransition');

          if ((hasDirtyAttributes || hasDirtyRelationships) && firstTry) {
            this.controller.set('confirmTransition', true);
            transition.abort();
            this.send('openModal', 'unsaved-changes');
            this.controller.get('unsavedChanges').set('transition', transition);
            this.controller.get('unsavedChanges').set('controller', this.controller);
          } else {
            this.controller.set('confirmTransition', false);
            return true;
          }
        }
      },

      updateRecord: function updateRecord() {
        var _this = this;

        var store = this.store;
        var recordName = this.controller.get('recordName');
        var recordId = this.controller.get('recordId');
        var belongsToRecords = this.controller.get('typeAheadBelongsTo');
        var transitionTo = this.controller.get('recordTransitionTo');
        this.controller.set('recordIsUpdating', true);

        store.find(recordName, recordId).then(function (record) {
          var vals = belongsToRecords || [];

          vals.forEach(function (val) {
            if (!isPresent(record.get(val).content)) {
              record.set(val, null);
            }
          });

          _this.controller.send('scrollToBottom');

          record.save().then(function () {
            _this.controller.setProperties({
              recordIsUpdating: false,
              recordIsUpdated: true
            });
            run.later(function () {
              _this.controller.set('recordIsUpdated', false);

              if (transitionTo) {
                _this.transitionTo(transitionTo);
              } else {
                _this.send('invalidateModel');
              }
            }, 2000);
          })['catch'](function (err) {
            _this.flashMessages.danger(err.message);
            _this.controller.set('recordIsUpdating', false);
          });
        });
      },

      confirmDeleteRecord: function confirmDeleteRecord() {
        var _this2 = this;

        var store = this.store;
        var recordName = this.controller.get('recordName');
        var recordId = this.controller.get('recordId');
        var transitionTo = this.controller.get('recordTransitionTo') || this.controller.get('deleteTransitionTo');

        store.find(recordName, recordId).then(function (record) {
          record.deleteRecord();

          record.save().then(function () {
            _this2.controller.set('recordIsDeleted', true);

            run.later(function () {
              _this2.controller.set('recordIsDeleted', false);
              _this2.controller.set('recordConfirmDelete', false);
              if (transitionTo) {
                _this2.transitionTo(transitionTo);
              }
            }, 2000);
          }, function (err) {
            _this2.flashMessages.danger(err.message);
            _this2.controller.set('recordConfirmDelete', false);
          });
        });
      }
    }
  });
  exports.RouteRecordEditor = RouteRecordEditor;
});