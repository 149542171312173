define('web/controllers/users', ['exports', 'ember', 'web/mixins/records-search', 'web/mixins/loading-model', 'web/mixins/load-more'], function (exports, _ember, _webMixinsRecordsSearch, _webMixinsLoadingModel, _webMixinsLoadMore) {
  var computed = _ember['default'].computed;
  exports['default'] = _ember['default'].Controller.extend(_webMixinsRecordsSearch.ControllerSearchMixin, _webMixinsLoadingModel.ControllerLoadingMixin, _webMixinsLoadMore.ControllerLoadMore, {
    recordName: 'user',

    queryParams: ['role', 'limit', 'offset', 'search'],
    role: null,

    availRoles: ['attendant', 'sub-operator', 'operator', 'admin'],
    availOperatorRoles: ['attendant', 'sub-operator'],

    userCols: computed('currentUser.isAdmin', function () {
      var userCols = [{}, {
        colName: 'Status',
        sortProp: 'status'
      }, {
        colName: 'Role',
        sortProp: 'role'
      }, {
        colName: 'Name',
        sortProp: 'name'
      }];

      if (this.get('currentUser.isAdmin')) {
        userCols.push({ colName: 'Company Name', sortCol: null }, {});
      } else {
        userCols.push({});
      }

      return userCols;
    }),

    sort: 'name',
    sortDir: 'asc',
    sortProps: computed('sort', 'sortDir', {
      get: function get() {
        return [this.get('sort') + ':' + this.get('sortDir')];
      }
    }),
    sortedUsers: computed.sort('model', 'sortProps'),

    filteredUsers: computed.filter('sortedUsers.@each.status', function (usr) {
      return usr.get('status') !== 'resumed';
    }),

    actions: {
      userApprove: function userApprove(user) {
        var _this = this;

        user.set('status', 'approved');
        user.save().then(function () {
          return _this.flashMessages.success('User Status Approved.');
        });
      },

      userDisprove: function userDisprove(user) {
        var _this2 = this;

        user.set('status', 'rejected');
        user.save().then(function () {
          return _this2.flashMessages.success('User Status Disproved.');
        });
      },

      userTransition: function userTransition(user) {
        this.transitionToRoute('user', user.id);
      },

      changeRole: function changeRole(role) {
        this.setProperties({
          limit: 20,
          offset: 0,
          role: role
        });
      },

      sortCol: function sortCol(col) {
        var isCurrentSort = this.get('sort') === col;

        if (isCurrentSort) {
          var newDir = this.get('sortDir') === 'asc' ? 'desc' : 'asc';
          this.set('sortDir', newDir);
        } else {
          this.set('sort', col);
          this.set('sortDir', 'asc');
        }
      }
    }
  });
});