define('web/routes/event-parkings', ['exports', 'ember', 'underscore', 'web/mixins/reset-scroll', 'web/mixins/load-more', 'web/mixins/loading-model', 'web/mixins/records-search', 'web/mixins/build-params', 'web/helpers/get-min-max'], function (exports, _ember, _underscore, _webMixinsResetScroll, _webMixinsLoadMore, _webMixinsLoadingModel, _webMixinsRecordsSearch, _webMixinsBuildParams, _webHelpersGetMinMax) {
  var set = _ember['default'].set;
  var get = _ember['default'].get;
  exports['default'] = _ember['default'].Route.extend(_webMixinsLoadingModel.RouteLoadingMixin, _webMixinsRecordsSearch.RouteSearchMixin, _webMixinsLoadMore.RouteLoadMore, _webMixinsResetScroll['default'], {

    queryParams: {
      search: { refreshModel: true }
    },

    model: function model(params) {
      var _this = this;

      var finalEvents = _ember['default'].A();
      var landmarkPromise = null;

      // If the currentUser is not an admin query the currentUsers landmarks.
      if (!this.get('currentUser.isAdmin')) {
        landmarkPromise = _ember['default'].RSVP.all(this.get('currentUser.landmarkIds').map(function (id) {
          return _this.store.find('landmark', id);
        }));

        return landmarkPromise.then(function (landmarks) {
          var uniqueLandmarks = _underscore['default'].uniq(landmarks, false, function (u) {
            return u.id;
          });

          return _ember['default'].RSVP.all(uniqueLandmarks.map(function (lm) {
            return _this.store.query('event', {
              fromLandmark: lm.id,
              getAll: true
            }).then(function (events) {
              var foundEvents = [];

              events.forEach(function (event) {
                foundEvents.push(event);
              });

              if (foundEvents.length === 0) {
                return;
              }

              return _ember['default'].RSVP.all(foundEvents.map(function (event) {
                return event.get('eventOptions').then(function (eventOptions) {
                  if (!eventOptions) {
                    return Promise.reject("no event options");
                  }

                  return eventOptions.get('lotsSellable');
                }).then(function (lotsSellable) {
                  var listPricings = _ember['default'].A();

                  return _ember['default'].RSVP.all(lotsSellable.map(function (lot) {
                    return _this.store.query('pricing', { lotId: lot.get('id'), eventId: event.get('id') }).then(function (pricings) {
                      pricings.forEach(function (pricing) {
                        listPricings.push(pricing.get('price'));
                      });

                      set(event, 'minPrice', (0, _webHelpersGetMinMax.getMinMax)(listPricings, 'min') / 100);
                      set(event, 'maxPrice', (0, _webHelpersGetMinMax.getMinMax)(listPricings, 'max') / 100);
                    });
                  }));
                }).then(function () {
                  // Set event name for filtering results with fuse.
                  event.name = event.get('name');
                  return finalEvents.push(event);
                })['catch'](function (err) {
                  console.log(err);
                });
              }));
            });
          }));
        }).then(function () {
          return finalEvents;
        });
      }

      return this.store.query('event', (0, _webMixinsBuildParams.filterParams)(params)).then(function (events) {
        if (events.length === 0) {
          return;
        }

        return events;
      });
    },

    setupController: function setupController(controller, model) {
      this._super(controller, model);

      controller.set('initialEvents', model);
    }
  });
});