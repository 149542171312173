define('web/components/datetime-picker', ['exports', 'ember'], function (exports, _ember) {

  Date.parseDate = function (input, format) {
    return moment(input, format).toDate();
  };
  Date.prototype.dateFormat = function (format) {
    return moment(this).format(format);
  };

  exports['default'] = _ember['default'].TextField.extend({
    classNames: ['datetimepicker'],
    id: 'datetimepicker',
    readonly: 'readonly',
    placeholder: 'Choose a Date...',

    didRender: function didRender() {
      this._super();
      this._setupDatepicker();
    },

    _setupDatepicker: function _setupDatepicker() {
      var _this = this;

      var config = {
        defaultDate: this.get('defaultDate'),
        startDate: this.get('startDate'),
        minDate: this.get('minDate'),
        maxDate: this.get('maxDate'),
        format: 'M/D/YYYY h:mm A',
        timepickerScrollbar: false,
        formatDate: 'M/D/YYYY',
        formatTime: 'h:mm A',
        step: 15,
        scrollMonth: false,
        value: this.get('value')
      };

      return this.$().datetimepicker(_ember['default'].$.extend(config, {
        onChangeDateTime: function onChangeDateTime(dp, $input) {
          _this.attrs.changeDateTime(dp, $input);
        }
      }));
    },

    willDestroyElement: function willDestroyElement() {
      this.$().datetimepicker('destroy');
      this._super();
    }
  });
});