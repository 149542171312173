define('web/routes/user/details', ['exports', 'ember', 'web/mixins/record-editor', 'web/mixins/reset-scroll', 'web/mixins/serialize-record', 'ic-ajax', 'web/config/environment'], function (exports, _ember, _webMixinsRecordEditor, _webMixinsResetScroll, _webMixinsSerializeRecord, _icAjax, _webConfigEnvironment) {
  var _slicedToArray = (function () { function sliceIterator(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i['return']) _i['return'](); } finally { if (_d) throw _e; } } return _arr; } return function (arr, i) { if (Array.isArray(arr)) { return arr; } else if (Symbol.iterator in Object(arr)) { return sliceIterator(arr, i); } else { throw new TypeError('Invalid attempt to destructure non-iterable instance'); } }; })();

  exports['default'] = _ember['default'].Route.extend(_webMixinsResetScroll['default'], _webMixinsRecordEditor.RouteRecordEditor, {
    model: function model() {
      var _this = this;

      var userModel = this.modelFor('user');

      this.set('foundUserOptions', null);
      this.set('noUserOptions', null);
      this.set('foundTaxOptionAvailable', null);

      return _ember['default'].RSVP.all([(0, _icAjax['default'])({
        method: 'GET',
        url: _webConfigEnvironment['default'].platformAPIUrl + '/api/v1/transactions/additional-charges/user-options?operatorLegacyId=' + userModel.get('id'),
        contentType: 'application/json',
        dataType: 'json',
        crossDomain: true,
        xhrFields: {
          withCredentials: true
        }
      }), this.store.query('user-option', { userId: userModel.get('id') })]).then(function (_ref) {
        var _ref2 = _slicedToArray(_ref, 2);

        var taxUserOptions = _ref2[0];
        var userOptions = _ref2[1];

        _this.set('foundUserOptions', (0, _webMixinsSerializeRecord.serialize)(_this.store, 'user-option', userOptions.content[0]));
        _this.set('foundTaxOptionAvailable', taxUserOptions.additionalChargesAvailable);

        return userModel;
      })['catch'](function () {
        _this.set('noUserOptions', true);

        return userModel;
      });
    },

    setupController: function setupController(controller, model) {
      this._super(controller, model);

      controller.set('taxOptionAvailable', this.get('foundTaxOptionAvailable'));
      controller.set('userOptions', this.get('foundUserOptions'));
      controller.set('noUserOptions', this.get('noUserOptions'));
    }

  });
});