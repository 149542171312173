define('web/controllers/user/details', ['exports', 'ember', 'web/mixins/record-editor', 'web/mixins/image-uploader-controller', 'web/mixins/error-message', 'ic-ajax', 'web/config/environment'], function (exports, _ember, _webMixinsRecordEditor, _webMixinsImageUploaderController, _webMixinsErrorMessage, _icAjax, _webConfigEnvironment) {
  var _Ember$computed = _ember['default'].computed;
  var alias = _Ember$computed.alias;
  var equal = _Ember$computed.equal;
  var computed = _ember['default'].computed;
  var run = _ember['default'].run;
  var later = _ember['default'].run.later;
  exports['default'] = _ember['default'].Controller.extend(_webMixinsRecordEditor['default'], _webMixinsErrorMessage['default'], _webMixinsImageUploaderController['default'], {
    unsavedChanges: _ember['default'].inject.controller('modals.unsaved-changes'),
    recordId: alias('model.id'),
    recordName: 'user',
    recordModel: 'user',
    recordTransitionTo: 'users',
    typeAheadBelongsTo: ['operatorId'],

    recordIsUpdated: false,
    errorMessages: null,

    isOperatorRecord: equal('model.role', 'operator'),
    isEmployee: computed('model.role', {
      get: function get() {
        return !!(equal('model.role', 'attendant') || equal('model.role', 'sub-operator'));
      }
    }),

    availRoles: ['attendant', 'sub-operator', 'operator', 'admin'],
    availOperatorRoles: ['attendant', 'sub-operator'],
    availStatuses: ['rejected', 'suspended', 'pending', 'approved', 'resumed'],
    passwordChange: false,
    passwordChanged: false,
    passwordVal: null,

    // ------------------- For Image Uploader Controller mixin
    imgKeyOnModel: 'companyLogo',
    dataKeyOnModel: 'logoData',
    // -------------------

    companyLogoBg: (function () {
      var p = this.get('model.companyLogo');
      if (p) {
        return ('background-image: url(' + p + ')').htmlSafe();
      }
    }).property('model.companyLogo'),

    _updateValidate: function _updateValidate() {
      var _this = this;

      this.set('recordIsUpdated', true);
      later(function () {
        _this.transitionToRoute('users');
        _this.set('recordIsUpdated', false);
      }, 2000);
    },

    actions: {
      toggleEventCreation: function toggleEventCreation() {
        var _this2 = this;

        this.toggleProperty('userOptions.eventCreation');

        // If eventCreation is true then turn on edit event functionality also.
        if (this.get('userOptions.eventCreation') && !this.get('userOptions.editEvent')) {
          return this.send('toggleEditEvent');
        }

        return this.get('userOptions').save().then(function () {
          _this2.flashMessages.success('Successfully Saved!');
        });
      },

      toggleEditEvent: function toggleEditEvent() {
        var _this3 = this;

        this.toggleProperty('userOptions.editEvent');

        return this.get('userOptions').save().then(function () {
          _this3.flashMessages.success('Successfully Saved!');
        });
      },

      updateTaxOption: function updateTaxOption() {
        var _this4 = this;

        this.toggleProperty('taxOptionAvailable');

        return (0, _icAjax['default'])({
          method: 'PUT',
          url: _webConfigEnvironment['default'].platformAPIUrl + '/api/v1/transactions/additional-charges/user-options',
          contentType: 'application/json',
          dataType: 'json',
          data: JSON.stringify({
            operatorLegacyId: this.get('model.id'),
            additionalChargesAvailable: this.get('taxOptionAvailable')
          }),
          crossDomain: true,
          xhrFields: {
            withCredentials: true
          }
        }).then(function () {
          _this4.flashMessages.success('Successfully Saved!');
        });
      },

      togglePrintPricingInitial: function togglePrintPricingInitial() {
        var _this5 = this;

        this.toggleProperty('userOptions.printPricingInitial');

        return this.get('userOptions').save().then(function () {
          _this5.flashMessages.success('Successfully Saved!');
        });
      },

      changePassword: function changePassword() {
        this.toggleProperty('passwordChange');
      },

      updateUser: function updateUser(user) {
        var _this6 = this;

        this.send('scrollToBottom');
        if (user.get('hasDirtyAttributes') && Object.keys(user.changedAttributes())[0] !== 'password') {
          user.save().then(function () {
            run(_this6, _this6._updateValidate);
          }, function (err) {
            return _this6.flashMessages.danger(err.message);
          });
        } else {
          run(this, this._updateValidate);
        }
      },

      goToOperatorLots: function goToOperatorLots() {
        this.transitionToRoute('lots', {
          queryParams: {
            user_id: this.get('model.id')
          }
        });
      },

      sendChangedPassword: function sendChangedPassword(model) {
        var _this7 = this;

        var obj = _ember['default'].Object.create({
          user: {
            password: this.get('passwordVal')
          }
        });

        var clear = function clear() {
          later(function () {
            _this7.get('model').reload();

            _this7.setProperties({
              passwordChanged: false,
              passwordChange: false
            });
            _this7.set('model.password', undefined);
            _this7.set('passwordVal', undefined);
          }, 1500);
        };

        return fetch(_webConfigEnvironment['default'].apiUrl + '/users/' + model.get('id'), {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
            'Data-Type': 'json'
          },
          credentials: 'include',
          body: JSON.stringify(obj)
        }).then(function (status) {
          if (status.status > 300) {
            throw status;
          } else {
            return status;
          }
        }).then(function () {
          _this7.set('passwordChanged', true);
          clear();
        })['catch'](function (err) {
          _this7.flashMessages.danger(err.message);
          clear();
        });
      },

      clearErrorMessages: function clearErrorMessages() {
        this.set('errorMessages', null);
      },

      cancelChangePassword: function cancelChangePassword() {
        this.set('passwordChange', false);
        this.set('model.password', undefined);
      },

      scrollToBottom: function scrollToBottom() {
        _ember['default'].$('html, body').animate({
          scrollTop: document.body.scrollHeight
        }, 600);
      }
    }
  });
});