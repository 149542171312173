define("web/components/search-filters", ["exports", "ember"], function (exports, _ember) {
  exports["default"] = _ember["default"].Component.extend({
    actions: {
      changeChecked: function changeChecked(val, checked) {
        // const selectedFilters = this.get('selectedFilters');
        var types = ["lots", "users", "landmarks", "events"];
        var params = ["operator", "sub-operator", "admin", "attendant"];

        if (types.contains(val)) {
          this.attrs.changeTypes(val, checked);
        }

        if (params.contains(val)) {}
      }
    }
  });
});