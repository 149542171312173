define('web/controllers/authenticated-apps/edit', ['exports', 'ember', 'ic-ajax', 'web/config/environment'], function (exports, _ember, _icAjax, _webConfigEnvironment) {
  exports['default'] = _ember['default'].Controller.extend({
    availRoles: ['guest', 'registered', 'attendant', 'sub-operator', 'operator', 'admin'],

    recordIsUpdated: false,
    recordConfirmDelete: false,
    recordIsDeleted: false,
    deleteError: false,

    actions: {
      resetSecret: function resetSecret(cb) {
        var m = this.get('model');

        var p = (0, _icAjax['default'])({
          method: 'DELETE',
          url: _webConfigEnvironment['default'].apiUrl + '/api/v2/authenticatedApps/' + m.get('id') + '/secret',
          crossDomain: true,
          xhrFields: {
            withCredentials: true
          }
        }).then(function (resp) {
          m.set('secret', resp.authenticatedApp.secret);
        });

        cb(p);
      },

      deleteRecord: function deleteRecord() {
        this.set('recordConfirmDelete', true);
      },

      confirmDeleteRecord: function confirmDeleteRecord() {
        var _this = this;

        this.store.find('authenticated-app', this.get('model.id')).then(function (app) {
          app.destroyRecord();

          _this.set('recordIsDeleted', true);

          _ember['default'].run.later(function () {
            _this.setProperties({
              name: null,
              description: null,
              recordIsDeleted: false,
              recordConfirmDelete: false
            });
            _this.transitionToRoute('authenticated-apps');
          }, 2000);
        });
      },

      cancelDelete: function cancelDelete() {
        this.set('recordConfirmDelete', false);
      },

      updateRecord: function updateRecord() {
        var _this2 = this;

        this.store.find('authenticated-app', this.get('model.id')).then(function (app) {
          app.save().then(function () {
            _this2.set('recordIsUpdated', true);

            _ember['default'].run.later(function () {
              _this2.transitionToRoute('authenticated-apps');
              _this2.set('recordIsUpdated', false);
            }, 2000);
          })['catch'](function (err) {
            return _this2.flashMessages.danger(err.message);
          });
        });
      }
    }
  });
});