define('web/routes/lot', ['exports', 'ember', 'web/mixins/nested-activate', 'web/mixins/serialize-record', 'fetch', 'web/config/environment'], function (exports, _ember, _webMixinsNestedActivate, _webMixinsSerializeRecord, _fetch, _webConfigEnvironment) {
  exports['default'] = _ember['default'].Route.extend(_webMixinsNestedActivate['default'], {
    model: function model(params) {
      var _this = this;

      this.set('curParams', params);

      return (0, _fetch['default'])(_webConfigEnvironment['default'].apiUrl + '/api/v2/lots/' + params.lot_id + '?withAssignments=true', {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Data-Type': 'json'
        },
        credentials: 'include'
      }).then(function (status) {
        if (status.status > 300) {
          throw status;
        } else {
          return status;
        }
      }).then(function (resp) {
        return resp.json().then(function (response) {
          return (0, _webMixinsSerializeRecord.serialize)(_this.store, 'lot', response.lot);
        });
      });
    },

    actions: {
      invalidateModel: function invalidateModel() {
        this.refresh();
      },

      loading: function loading() {
        return false;
      },

      error: function error(_error) {
        if (_error && _error.statusCode === 403) {
          this.flashMessages.danger('You are not authorized to view this lot.');
          return this.transitionTo('lots');
        }
      }
    }
  });
});