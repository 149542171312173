define('web/controllers/permits', ['exports', 'ember', 'moment', 'web/mixins/records-search', 'web/mixins/load-more', 'web/mixins/loading-model'], function (exports, _ember, _moment, _webMixinsRecordsSearch, _webMixinsLoadMore, _webMixinsLoadingModel) {
  var computed = _ember['default'].computed;
  exports['default'] = _ember['default'].Controller.extend(_webMixinsRecordsSearch.ControllerSearchMixin, _webMixinsLoadingModel.ControllerLoadingMixin, _webMixinsLoadMore.ControllerLoadMore, {
    queryParams: ['search', 'limit', 'offset'],
    getActive: false,
    recordName: 'permit',
    fileObj: {
      file: null
    },

    permits: computed('model', 'getActive', 'search', function () {
      var isActive = this.get('getActive');
      var currentDateTime = (0, _moment['default'])().format();

      if (isActive) {
        return this.get('model').filter(function (t) {
          var isActivePermit = t.get('active') && isActive && t.get('validTo') >= currentDateTime || t.get('active') && isActive && t.get('validTo') === null;

          if (isActivePermit) {
            return t;
          }
        });
      }

      return this.get('model').filter(function (t) {
        if (t.get('validTo') <= currentDateTime) {
          return t;
        }
      });
    }),

    permitCols: [{ colName: 'Name' }, { colName: 'E-mail' }, { colName: 'Operator' }, {}],

    actions: {
      invalidateModel: function invalidateModel() {
        return true;
      },

      toggleSelected: function toggleSelected() {
        this.toggleProperty('getActive');
      },

      permitTransition: function permitTransition(permitId) {
        this.transitionToRoute('permit', permitId);
      }
    }

  });
});