define('web/controllers/my-account', ['exports', 'ember', 'web/mixins/record-editor', 'web/mixins/image-uploader-controller', 'web/mixins/error-message', 'web/config/environment'], function (exports, _ember, _webMixinsRecordEditor, _webMixinsImageUploaderController, _webMixinsErrorMessage, _webConfigEnvironment) {
  var _Ember$computed = _ember['default'].computed;
  var alias = _Ember$computed.alias;
  var equal = _Ember$computed.equal;
  var later = _ember['default'].run.later;
  var run = _ember['default'].run;
  exports['default'] = _ember['default'].Controller.extend(_webMixinsRecordEditor['default'], _webMixinsImageUploaderController['default'], _webMixinsErrorMessage['default'], {
    unsavedChanges: _ember['default'].inject.controller('modals.unsaved-changes'),
    recordId: alias('model.id'),
    recordName: 'user',
    recordModel: 'user',
    recordTransitionTo: 'users',

    recordIsUpdating: false,
    recordIsUpdated: false,

    isOperatorRecord: equal('model.role', 'operator'),

    // ------------------- For Image Uploader Controller mixin
    imgKeyOnModel: 'companyLogo',
    dataKeyOnModel: 'logoData',
    // -------------------

    _updateValidate: function _updateValidate() {
      var _this = this;

      this.set('recordIsUpdating', false);
      this.set('recordIsUpdated', true);
      later(function () {
        _this.set('recordIsUpdated', false);
        window.location.reload(true);
      }, 2000);
    },

    actions: {
      changePassword: function changePassword() {
        this.toggleProperty('passwordChange');
      },

      updateUser: function updateUser(user) {
        var _this2 = this;

        this.set('recordIsUpdating', true);

        if (user.get('hasDirtyAttributes') && Object.keys(user.changedAttributes())[0] !== 'password') {

          if (user.get('role') === 'operator' || user.get('role') === 'admin') {
            user.set('operatorId', null);
          }

          user.save().then(function () {
            run(_this2, _this2._updateValidate);
          })['catch'](function (err) {
            _this2.flashMessages.danger(err.message);
          });
        } else {
          run(this, this._updateValidate);
        }
      },

      sendChangedPassword: function sendChangedPassword(model) {
        var _this3 = this;

        var obj = _ember['default'].Object.create({
          user: {
            password: this.get('passwordVal')
          }
        });

        var clear = function clear() {
          later(function () {
            _this3.get('model').reload();
            _this3.setProperties({
              passwordChanged: false,
              passwordChange: false
            });
            _this3.set('model.password', undefined);
            _this3.set('passwordVal', undefined);
          }, 1500);
        };

        return fetch(_webConfigEnvironment['default'].apiUrl + '/api/v2/users/' + model.get('id'), {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
            'Data-Type': 'json'
          },
          credentials: 'include',
          body: JSON.stringify(obj)
        }).then(function (status) {
          if (status.status > 300) {
            throw status;
          } else {
            return status;
          }
        }).then(function () {
          _this3.set('passwordChanged', true);
          clear();
        })['catch'](function (err) {
          _this3.flashMessages.danger(err.message);
          clear();
        });
      },

      cancelChangePassword: function cancelChangePassword() {
        this.setProperties({
          passwordChange: false,
          errorMessages: null
        });
        this.set('model.password', undefined);
      }
    }

  });
});